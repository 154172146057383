import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { CustomButton } from 'src/components/CustomButton';
import { useDeviceActivationStyles } from './DeviceActivation.styles';
import { useDeviceWizzardContext } from 'src/contexts/AddDeviceWizzard';
import { useHistory } from 'react-router-dom';
import { Routes } from 'src/routes/routes';
import { axiosInstace, IErrorResponse } from 'src/services/axiosInstance';
import { AxiosError } from 'axios';

interface DeviceActivationProps {
}

export const DeviceActivation: React.FC<DeviceActivationProps> = () => {
  const classes = useDeviceActivationStyles();
  const history = useHistory();
  const { deviceWizzard, clearState, handleBack } = useDeviceWizzardContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const finalize = (): void => {
    clearState();
    history.push(Routes.DASHBOARD);
  };

  const finalizeErrorCase = (): void => {
    handleBack();
    handleBack();
    handleBack();
  };

  const addDevice = async () => {
    if (!deviceWizzard) return;
    const copiedDevice = {
      ...deviceWizzard
    };
    const appKey = copiedDevice.deviceTtnSettings?.appKey;
    const deviceEui = copiedDevice.deviceTtnSettings?.deviceEui;
    const joinEui = copiedDevice.deviceTtnSettings?.joinEui;
    delete copiedDevice.deviceTtnSettings;

    const device = {
      address: {
        buildingId: copiedDevice.buildingId,
        flatId: copiedDevice.flatId
      },
      addDeviceDto: {
        name: copiedDevice.name,
        deviceEui: deviceEui,
        joinEui: joinEui,
        appKey: appKey,
        description: copiedDevice.description,
        networkType: copiedDevice.networkType,
        messageGenerationPeriodSeconds: copiedDevice.messageGenerationPeriodSeconds,
        loraStorePeriodSeconds: copiedDevice.loraStorePeriodSeconds,
        expirationDate: copiedDevice.expirationDate,
        legalizationEndDate: copiedDevice.legalizationEndDate
      }
    };

    if (copiedDevice.deviceType === 'heatMeter') {
      await axiosInstace
        .post('/api/locations/heatMeters',
          {
            ...device,
            addDeviceDto: {
              ...device.addDeviceDto,
              heatMeterProperties: copiedDevice.heatMeterProperties
            }
          }, {
            headers: {
              'tenant-id': copiedDevice.tenantId
            }
          })
        .then((response) => {
          if (response.status === 204) {
            setIsLoading(false);
            setIsSuccess(true);
          }
        })
        .catch((err) => {
          setIsSuccess(false);
          setIsLoading(false);
          if (err && err.response) {
            const axiosError = err as AxiosError<IErrorResponse>;
            if (axiosError.response?.data.errors) {
              setErrorMessage(axiosError.response?.data.errors[0]);
              setIsLoading(false);
              return;
            }
            if (axiosError.response?.data.title) {
              setIsLoading(false);
              setErrorMessage(axiosError.response?.data?.title);
              return;
            }
          }
          throw err;
        });
    }

    if (copiedDevice.deviceType === 'waterMeter') {
      await axiosInstace
        .post('/api/locations/waterMeters', {
          ...device,
          addDeviceDto: {
            ...device.addDeviceDto,
            waterMeterProperties: copiedDevice.waterMeterProperties
          }
        }, {
          headers: {
            'tenant-id': copiedDevice.tenantId
          }
        })
        .then((response) => {
          if (response.status === 204) {
            setIsLoading(false);
            setIsSuccess(true);
          }
        })
        .catch((err) => {
          setIsSuccess(false);
          if (err && err.response) {
            const axiosError = err as AxiosError<IErrorResponse>;
            if (axiosError.response?.data.errors) {
              setErrorMessage(axiosError.response?.data.errors[0]);
              setIsLoading(false);
              return;
            }
            if (axiosError.response?.data.title) {
              setIsLoading(false);
              setErrorMessage(axiosError.response?.data?.title);
              return;
            }
          }
          throw err;
        });
    }
  };

  useEffect(() => {
    setIsLoading(true);
    addDevice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      className={classes.container}
      direction="column"
    >
      {!isLoading ? (
        isSuccess ? (
          <>
            <Typography align="center" variant="h5">
              Aktywuj urządzenie zgodnie z zaleceniami producenta
            </Typography>
            <div className={classes.iconContainer}>
              <CheckCircleOutlineIcon className={classes.icon} />
            </div>
            <div className={classes.buttons}>
              <CustomButton
                variant="contained"
                color="primary"
                onClick={finalize}
                className={classes.submitText}
              >
                Sprawdź listę urządzeń
              </CustomButton>
            </div>
          </>
        ) : (
          <>
            <Typography align="center" variant="h5">
              {errorMessage}
            </Typography>
            <div className={classes.iconContainer}>
              <CheckCircleOutlineIcon className={classes.iconFailed} />
            </div>
            <div className={classes.buttons}>
              <CustomButton
                variant="contained"
                color="primary"
                onClick={finalizeErrorCase}
                className={classes.submitText}
              >
                Spróbuj ponownie
              </CustomButton>
            </div>
          </>
        )
      ) : (
        <CircularProgress />
      )}
    </Grid>
  );
};
